/* 
Use the page for existing features dependent on a style type that no longer exists in Bootstrap v4.
Ideally, these features will be removed once the content is redesigned.
*/

@import '../../variables_mixins/variables.scss';

.fileinput-button {
    overflow: hidden;
    position: relative;

    input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        border: solid transparent;
        border-width: 0 0 100px 200px;
        opacity: 0;
        filter: alpha(opacity=0);
        direction: ltr;
        cursor: pointer;
    }
}
.nav-list .active a {
    background-color: $button-color;
    color: $white-color;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .02%);
}

/* 
  Keeps Bootstrap 2 handling of the following pseudo elements.
*/
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
